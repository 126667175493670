<template>
<div class="modal">
  <div class="modal__body dialog-notification__body">
    <div v-if="title" v-html="title" class="dialog-notification__title h2"/>
    <div v-if="message" v-html="message" class="dialog-notification__message h5 semibold"/>

    <div class="modal__close" @click="closeNotificationModal">
      <img src="~/assets/img/svg/common/close.svg"/>
    </div>
  </div>
  <div
    class="modal__overlay"
    @click="closeNotificationModal"
  />
</div>
</template>

<script setup lang="ts">
import { useNotificationModal } from '~/composables/useNotificationModal';
import { computed } from 'vue';

const {
  infoNotificationModal,
  closeNotificationModal
} = useNotificationModal();

const title = computed(() => {
  return infoNotificationModal.value?.title || ""
});
const message = computed(() => {
  return infoNotificationModal.value?.message || ""
});
</script>

<style scoped lang="scss">
.dialog-notification__body {
  max-width: 550px;
  padding: 40px;

  .modal__close {
    width: 37px;
    height: 37px;
    border-radius: 10px;
    background: #F4F3F1;
  }
}
.dialog-notification__title {
  margin-bottom: 16px;
  color: black;
}
.dialog-notification__message {
  color: #989898;
}
</style>
